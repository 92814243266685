@import './App.module.scss';

.landing {
    width: 100%;
    height: calc(100vh - 60px);

    display: flex;
    justify-content: center;

    position: relative;

    z-index: 0;
    background-color: $blk;

    .heroVideo {
        width: 100%;
        object-fit: cover;
        filter: brightness(0.8);

        position: relative;
        z-index: 5;
    }

    .privacyScreen {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $blk;
        z-index: 20;
        // user-select: none;
    }

    .menuContainer {
        position: fixed;
        z-index: 10;
        color: $grn;

        opacity: 0.95;

        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }
    .menu {
      display: flex;
      flex-direction: column;
      position: absolute;
      margin-bottom: 80px;

    }

        .wrapper {
            display: flex;
            align-items: flex-start;
            cursor: pointer;
            padding-bottom: 180px;

            p {
              font-family: 'Londona';
              animation: 3s ebb cubic-bezier(0.45, 0, 0.55, 1) infinite;

              text-shadow: 0 0 $red, 0 0 #fc9e05, 0 0 $blu;
              transform-origin: 50% 100%;
              color: $grn;
              
              position: relative;
              transform-style: preserve-3d;
              margin-right: 4px;
              text-shadow: 0px 0px 0px #198f6b, 0px 0px 0px #1b5252;

              mix-blend-mode: screen;

              @for $i from 1 through 7 {
                &:nth-child(#{$i}) {
                  animation-delay: 60ms * $i;
                }
              }
            }
            &:hover p {
                animation: 2s neutral cubic-bezier(0.45, 0, 0.55, 1) forwards;
            }
          }
          @keyframes ebb {
            20% {
              text-shadow: 4px 0px $blu, 8px 0px $orn, 12px 0px $red; 
            }
          }
          @keyframes neutral {
            0% {
                text-shadow: 4px 0 #23795f, 8px 0 #1b5252, 10px 0 #1b5252;
            }
            80% {
                text-shadow: 0px 0 #23795f, 0px 0 #1b5252, 0px 0 #1b5252;
            }
          }
          

        .content {
            width: 600px;
            height: 300px;
            font-family: 'Londona';

            position: relative;

            display: grid;
            grid-template-columns: 100%;
            grid-template-rows: 100%;

        }
        .content h2 {
            font-size: 8em;
            position: relative;
            grid-area: 1 / 1 / 2 / 2;
        }
        .content h2:nth-child(1) {
            z-index: 1;
            color: transparent;
            -webkit-text-stroke: 2px $grn;
        }
        
        .content h2:nth-child(2) {
            z-index: 2;
            color: $red;
            animation: animate 4s ease-in-out infinite;
        }
        
        @keyframes animate {
            0%,
            100% {
                clip-path: polygon(
                    0% 45%,
                    16% 44%,
                    33% 50%,
                    54% 60%,
                    70% 61%,
                    84% 59%,
                    100% 52%,
                    100% 100%,
                    0% 100%
                );
                color: $orn;
            }
        
            50% {
                clip-path: polygon(
                    0% 60%,
                    15% 65%,
                    34% 66%,
                    51% 62%,
                    67% 50%,
                    84% 45%,
                    100% 46%,
                    100% 100%,
                    0% 100%
                );
                color: $red;
            }
        }
        
        .title {
            text-decoration: none;
            user-select: none;
            text-align: left;
            padding: 25px 0;
            font-family: 'Londona';
    
        }

        .portfolioMenu {
            text-decoration: none;
            user-select: none;
            cursor: pointer;
            text-align: left;
            padding: 25px 0;
        
            &:hover {
                color: $ylw;
              }
              
        }
    

    
}
@media only screen and (min-width: 1024px) {
    /* CSS rules for desktops go here */
    .menuContainer {
        .wrapper {
            p {
            //   font-size: 16vw;
              font-size: 14rem;
            }}}
    .title {
        font-size: 8rem;
    }

    .portfolioMenu {
        font-size: 3.2rem;
    }
}

@media only screen and (max-width: 767px) {
    /* CSS rules for mobile phones go here */

    .menuContainer {
        .wrapper {
            p {
            //   font-size: 18vw;
              font-size: 5rem;
            }}}
    .menuContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .title {
        font-size: 4.5rem;
    }

    .portfolioMenu {
        font-size: 2.5rem;
    }
}