@import './App.module.scss';

.bio {
    width: 100%;

    display: flex;
    justify-content: center;
    position: relative;

    .privacyScreen {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #191716;
        background-color: $grn;
        z-index: 20;
    }

    .bioHero {
        display: grid;
    }

    .topSection {
        .pfpTop {
            border-radius: 50%;
            filter: brightness(1.2);
            border-width: 12px;
            border-style:solid;
            border-color: $orn;
            animation: 30s colorRotate linear infinite;
            box-sizing: border-box;
        }
        @keyframes colorRotate {
            0% {border-color: $orn;}
            30% {border-color: $orn;}
            33% {border-color: $red;}
            63% {border-color: $red;}
            66% {border-color: $blu;}
            96% {border-color: $blu;}
            100% {border-color: $orn;}
          }

        .topIntro {
            font-weight: 700;
            color: $grn;
            text-align: center;
            font-family: 'Londona';
        }
    }

    .botSection {
        
        .botIntro {
            font-weight: 700;
            color: $grn;
            
            font-family: 'Londona';
    
            & .selfText {
                margin-right: 40px;
            }
        }
        // .botIntroSig {
        //     display: flex;
        //     font-size: 3em;
        //     color: $blk;
        //     position: absolute;
        //     z-index: 4;
        //     text-align: right;
        //     top: 240px;
        //     left: 650px;
    
        //     & span {
        //         margin-right: 40px;
        //     }
        // }
    }  

    .midSection {
        position: relative;
        z-index: 3;
        background-color: $whi;
        

        width: 100%;
        opacity: 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        font-weight: 700;

        font-family: 'Overpass';
        box-sizing: border-box;
        
        .midDetails {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-bottom: 3px;
        }
    }

    .bioDescription {
        font-family: 'Overpass';

        cursor: default;
        
    }

    .bioCTA {

        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Overpass';

        & p {
            font-size: 3em;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            
            padding-bottom: 10px;

            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }

            .spacer {
                margin-left: -5px;
            }
        }

        &:hover {
            
            color: $grn;
        }
    }
}

@media only screen and (min-width: 1024px) {
    /* CSS rules for desktops go here */
    
    .bioHero {
        grid-template-rows: 320px 50px 320px 1fr 160px;
        grid-template-columns: 640px;
        margin-top: 50px;
    }

    .accent {
        color: $grn;
        &:hover {
            border-bottom: 2px solid $grn;
        }
    }

    
    .topSection {
        position: relative;
        z-index: 1;

        .pfpTop {
            position: relative;
            z-index: 2;
        }

        .topIntro {
            font-size: 7em;
            font-weight: 700;
            color: $grn;
            position: absolute;
            z-index: 3;
            text-align: left;
            top: -5px;
            left: -150px;
            font-family: 'Londona';
    
        }
    }

    .botSection {
        position: relative;
        z-index: 1;
        
        .pfpBot {
            position: relative;
            z-index: 2;
        }
        .botIntro {
            display: flex;
            font-size: 7em;
            font-weight: 700;
            position: absolute;
            z-index: 3;
            text-align: right;
            top: 155px;
            left: 85px;
    
    
            & .selfText {
                margin-right: 40px;
            }
        }
    }

    .bioDescription {

        font-size: 1.4em;
        margin-top: 100px;
        padding-bottom: 70px;
        & p {
            text-align: justify;
            margin-bottom: 20px;
        }
        .bioTitle {
            display: none;
        }
    }

    .bioCTA {
        padding-bottom: 200px;
    }
}

@media only screen and (max-width: 767px) {
    /* CSS rules for mobile phones go here */
    .bio {
        background-color: $grn;
        color: $whi
    }

    .accent {
        font-weight: bold;
        text-decoration: underline;
    }

    .bioHero {
        grid-template-rows: max-content 35px max-content 1fr 160px;
        grid-template-columns: 100%;

        max-width: 100vw;
    }
    
    .topSection {
        max-width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;

        justify-content: center;
        align-items: center;
        padding: 10px;

        .pfpTop {
            max-width: 100%;
            position: relative;
            z-index: 2;
        }

        .topIntro {
            display: none;
    
        }
    }

    .botSection {
        max-width: 100%;
        position: relative;
        z-index: 1;
        
        .pfpBot {
            max-width: 100%;
            position: relative;
            z-index: 2;
        }
        .botIntro {
            display: none;
        }
    }

    .midSection {
        padding: 0px 8px;
    }

    .bioDescription {
        
        .bioTitle {
            font-weight: 500;
            color: $whi;
            text-align: center;
            font-family: 'Londona';
            font-size: 4rem;
            margin-bottom: 60px;
        }
        
        & p {
            text-align: left;
            font-size: 1.2rem;
            padding: 0 20px;
                margin-bottom: 20px;
        }
    }
}