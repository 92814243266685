@import './App.module.scss';

.shell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    color: $grn;
    background-color: $blk;
    box-sizing: border-box;

    position: fixed;
    z-index: 666;
    padding: 0px 20px;


    .controlWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .menu {
        width: 20px;
        height: 40px;
        position: relative;

        button {
            background: $blk;
            border: none;
            border-radius: 10px;

            padding: 10px 0px;
            font-size: 18px;
            font-weight: 700;
            cursor: pointer;
            width: 100%;
            text-align: left;
            margin-bottom: 10px;
            
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          ul {
            display: flex;
            flex-direction: column;
            gap: 10px;
            background: $blk;
            width: 100px;
          }
          
          li {
            color: $grn;
            text-align: left;
            display: block;

            &:hover {
                color: $ylw;
                cursor: pointer;
            }
          }
          
          ul,
          li {
            list-style: none;
            margin: 0;
            padding: 10px;
          }
    }


    .main {
        font-size: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-left: 14px;
        color: $whi;

        .brackets {
            color: $red;
            font-size: 28px;
            animation: 11s colorRotate linear infinite;
        }
        .neutral {
            animation: none;
        }
        @keyframes colorRotate {
            10% {
              color: $grn; 
            }
            35% {
                color: $blu;
            }
            60% {
                color: $orn;
            }
            85% {
                color: $red;
            }
          }
        &:hover .brackets {
            color: $red;
            animation: none;
        }
    }


    .resume {
        border-radius: 5px;
    }

    .resume a {
        text-decoration: none;
        color: $blk;
        padding: 5px 8px 3px 8px;
        display: flex;
        justify-content: center;
        text-align: center;
        cursor: pointer;
    }
    
    .ctaGrn {
        background-color: $grn;
        &:hover {
            background-image: linear-gradient(to right, $red , $ylw);
        }
    }
    .ctaRed {
        background-color: $red;
        &:hover {
            background-image: linear-gradient(to right, $ylw , $grn);
        }
    }
    .ctaOrn {
        background-color: $orn;
        &:hover {
            background-image: linear-gradient(to right, $grn , $red);
        }
    }
    .ctaBlu {
        background-color: $blu;
        &:hover {
            background-image: linear-gradient(to right, $gry , $grn);
        }
    }
}