@import './App.module.scss';

.social {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
        box-sizing: border-box;
        margin-top: 50px;
        text-align: justify;
        font-weight: 700;

        display: flex;
        flex-direction: column;

        .titleBot {
            text-align: right;
        }

        .accent {
            color: $orn;
        }
    }

    .mediaLinks {
        display: grid;
        margin-top: 50px;
        box-sizing: border-box;
    }

    .linkGrid {
        display: grid;
        grid-template-columns: 1fr 220px 40px;
        align-items: center;
        cursor: pointer;

        border-bottom: 1px solid $orn;

        .network, .tag {
            padding-bottom: 5px;
        }
        .network {
            text-align: left;
        }
        .tag {
            text-align: right;
        }
        &:hover .tag {
            color: $orn;
        }
    }

    .inactive {
        pointer-events: none;
        color: $gry;
        text-decoration: line-through;
    }

    .disclaimer {
        text-align: justify;
        font-size: 0.7em;
        color: $gry;
        user-select: none;

        .copyright {
            width: 100%;
            text-align: center;
            color: $orn;
        }

        .update {
            padding-top: 20px;
            width: 100%;
            text-align: center;

            .accent {
                color: $orn;
            }
        }
    }

    .privacyScreen {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $orn;
        z-index: 20;
    }
}

@media only screen and (min-width: 1024px) {
    /* CSS rules for desktops go here */

    .title {
        width: 980px;
        font-size: 5.2em;
    }
    .mediaLinks {
        grid-template-columns: 450px 450px;
        grid-template-rows: 40px 40px 120px;
        gap: 80px;
    }

    .disclaimer {
        grid-area: 3 / 2 / 4 / 3;
    }
}

@media only screen and (max-width: 767px) {
    /* CSS rules for mobile phones go here */

    .title {
        width: 100%;
        font-size: 2.2em;
        padding: 0px 10px;
    }
    
    .mediaLinks {
        padding: 0px 20px;
        width: 100%;
        grid-template-columns: 100%;
        grid-template-rows: 25px 25px 25px 25px 1fr;
        gap: 80px;
    }

    .disclaimer {
        padding: 0px 20px;
    }
}