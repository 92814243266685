// @import '../packages/design/Styles/Main.module.scss';
@import url('https://fonts.googleapis.com/css2?family=Overpass:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
    font-family: 'Londona';
    src: url('../Assets/Fonts/Londona-reguler.otf') format('opentype');
         font-weight: 500;
         font-display: swap;
         font-style: normal;
}



$grn: #18e0a4;
$red: #FF495C;
$orn: #F9A620;
$ylw: #ffd166;
$blu: #007991;
$blk: #191716;
$gry: #AAB6C5;
$whi: #FFFBFA;

:export {
    red: $red;
    grn: $grn;
    orn: $orn;
    ylw: $ylw;
    gry: $gry;
    blu: $blu;
    blk: $blk;
    whi: $whi;
}

  

*, body {
    margin: 0;
    padding: 0;
}
html {
    scrollbar-width: none;
    
}
.app {
    background-color: $whi;

    text-align: center;
    width: 100%;
    min-height: 100vh;

    margin: 0;

    font-family: 'Overpass';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

