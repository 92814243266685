@import './App.module.scss';

.portfolio {
    display: flex;
    justify-content: center;
    
    .projectsContainer {
        padding-bottom: 200px;
        padding-top: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 800px;
        height: 100vh;
        overflow: scroll;
        scrollbar-width: none;
    }

    .projectWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        
    }
    .project {
        box-sizing: border-box;
        margin-bottom: 20px;
        
        width: 100%;
        height: 120px;

        transition: height 2s;

        font-weight: 700;
        text-align: left;

        border-width: 3px;
        border-style: solid;
        border-image: 
            linear-gradient(
            to bottom, 
            $red, 
            rgba(0, 0, 0, 0)
            ) 1 100%;


        .title {
            padding-left: 20px;
            font-family: 'Londona';
            
            &:hover {
                color: $red;
                cursor: pointer;
            }
        }

        .jobInfo {
            padding-left: 20px;
            font-weight: 600;
            font-size: 1rem;
            color: $gry;

            .jobDivider {
                color: $red;
            }
        }

        ul {
            padding-left: 40px;
            font-size: 1rem;
            font-weight: 100;
            color: black;
            margin-top: 20px;

            li {
                margin-bottom: 16px;
                text-align: left;

                &::marker {
                    color: $red;
                  }
            }
        }
        .description {
            font-weight: 100;
            padding: 0px 20px;
            margin-top: 20px;
        }
        .toggleAchievements {
            font-size: 0.8rem;
            cursor: pointer;
            text-align: right;
            padding-top: 20px;
            color: $gry;
            padding-right: 20px;
        }
        &:hover {
            .toggleAchievements {

                color: $red;
            }
        }
    }
    .expanded {
        height: min-content;
        transition: height 2s;
    }

    

    .vidContainer {
        border-top: 3px solid $red;
        position: relative;
        top: -3px;
    }
    .projectVideo {
        width: 100%;
        height: 200px;
    }

    .videoPga {
        object-fit: cover;
    }
    .videoEon {
        object-fit: cover;
        filter: brightness(.6) ;
    }
    .videoNuv {
        object-fit: cover;
        filter: brightness(.8) ;
    }
    .videoCot {
        object-fit: cover;
        filter: brightness(.8) ;
    }
    .videoLud {
        object-fit: cover;
    }


    .privacyScreen {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $red;
        z-index: 20;
    }

    .projectFooter {
        width: 100%;
        cursor: pointer;
        margin-bottom: 20px;

        &:hover .arrowUp {
            color: $red;
        }

    }

    .arrowUp {
        color: $gry;
    }
    
}


@media only screen and (min-width: 1024px) {
    /* CSS rules for desktops go here */
    .title {
        font-size: 3.6em;
    }

    li {
        width: 82%;
    }
}

@media only screen and (max-width: 767px) {
    /* CSS rules for mobile phones go here */
    .portfolio {
        padding: 0px 4px;
    }
    
    .title {
        font-size: 2.4em;
    }

    li {
        // width: 100%;
        padding-right: 20px;
    }
}

