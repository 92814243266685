@import './App.module.scss';

.blog {
    width: 100%;

    display: flex;
    justify-content: center;

    .postsWrapper {
        height: 100%;
        display: grid;
        gap: 20px;

        padding: 50px 0px;
    }

    .post {

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        width: 300px;
        height: 500px;

        padding: 20px 10px 10px;

        border-radius: 20px;
        border: 2px solid $blk;
        
        &:hover .title {
            color: $blu;
        }

        &:hover .postBanner {
            filter: grayscale(0);
        }
        
        .title {
            text-align: left;
            font-size: 2rem;
            font-weight: 700;
            min-height: 160px;
            max-height: 160px;
            cursor: pointer;
            transition: color 0.5s;
        }

        

        .metadata {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 12px;

        }

        .postCTA {
            all: unset;

            box-sizing: border-box;
            
            background-color: $blk;
            color: $whi;
            font-size: 0.9rem;
            
            padding: 8px 12px 6px;
            border-radius: 3px;
            
            cursor: pointer;

            &:focus {
                outline: revert;
            }
        }
        .date {
            font-size: 1.2rem;
            font-weight: 700;
        }
        
        .readingTime {
            color: $blu;
            display: flex;
            align-items: center;
            font-size: 1.2rem;
            font-weight: 700;
        }

        .postBanner {
            width: 100%;
            height: 200px;
            object-fit: cover;
            filter: grayscale(1);
            border-radius: 3px;
            cursor: pointer;

            transition: filter 0.5s;

        }

        .tagsContainer {
            display: flex;
            gap: 4px;
            .tag {
                display: flex;
                justify-content: center;
                align-items: center;
                
                padding: 8px 16px;
                border-radius: 20px;
    
                box-sizing: border-box;
                background-color: $blu;
                color: $whi;
                
            }
        }
    }

    .privacyScreen {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $blu;
        z-index: 20;
    }
}

@media only screen and (min-width: 1024px) {
    /* CSS rules for desktops go here */
    
    .postsWrapper {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 767px) {
    /* CSS rules for mobile phones go here */
    .postsWrapper {
        grid-template-columns: 300px;
    }
}